import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import PrivateRoute from './private-route';
import Spinner from './components/spinner/Spinner';

const Login = lazy(() => import('./views/login'));
const Home = lazy(() => import('./views/home'));
const NotFound = lazy(() => import('./views/not-found'));

const App = () => {
  const { accessToken } = useSelector((state: any) => state.authentication);
  const isAuthenticated = accessToken !== null;
  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/login">
            {isAuthenticated ? <Redirect to="/" /> : <Login />}
          </Route>
          <Route exact path="/">
            <Redirect to="/home/recipes/" />
          </Route>
          <Route exact path="/home/">
            <Redirect to="/home/recipes/" />
          </Route>
          <PrivateRoute
            path="/home"
            exact={false}
            isAuthenticated={isAuthenticated}
            component={Home}
          />
          <Route render={() => <NotFound />} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
