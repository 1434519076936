import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

export interface ISpinner {
  label?: string;
}

const Spinner = ({ label }: ISpinner) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress data-test-id="spinner" className={classes.spinner} />
      <Typography data-test-id="label" variant="subtitle1">
        {label}
      </Typography>
    </div>
  );
};

Spinner.defaultProps = {
  label: 'Loading...',
};

export default Spinner;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(7),
    padding: theme.spacing(7),
  },
  spinner: {
    margin: theme.spacing(2),
  },
}));
