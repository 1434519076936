import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios';

export const getCategories = createAsyncThunk(
  'getCategories',
  async (_, { getState }: { getState: Function }) => {
    let { shouldRefresh, categories } = getState().categories;

    if (!categories.length || shouldRefresh) {
      const { data } = await axios.get(`/categories`);

      categories = data.map((category: string) => {
        return {
          label: category,
          value: category,
        };
      });
    }

    return { categories };
  },
);
