import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios';

// const timeout = (ms: number) =>
//   new Promise((resolve) => setTimeout(resolve, ms));

export const getImage = createAsyncThunk(
  'getImage',
  async ({ id }: { id: string }, { getState }: { getState: Function }) => {
    const { data } = await axios.get(`/images/${id}`, {});
    const image = data;
    return { image };
  },
);

export const postImage = createAsyncThunk(
  'postImage',
  async ({ uri }: { uri: string }, { getState }: { getState: Function }) => {
    const { data } = await axios.post(`/images/`, { uri });
    // await timeout(1000);
    return { image: data };
  },
);
