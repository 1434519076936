import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios';

export const getCuisines = createAsyncThunk(
  'getCuisines',
  async (_, { getState }: { getState: Function }) => {
    let { shouldRefresh, cuisines } = getState().cuisines;

    if (!cuisines.length || shouldRefresh) {
      const { data } = await axios.get(`/cuisines`);

      cuisines = data.map((category: string) => {
        return {
          label: category,
          value: category,
        };
      });
    }

    return { cuisines };
  },
);
