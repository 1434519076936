import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'hsl(212, 35%, 56%)',
    },
    secondary: {
      main: 'hsl(15, 85%, 35%)',
    },
    error: {
      main: 'hsl(25, 75%, 65%)',
    },
    background: {
      default: 'hsl(38, 60%, 98%)',
      paper: 'hsl(38, 30%, 99%)',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});
