import { createSlice } from '@reduxjs/toolkit';
import { getRecipe, putRecipe, postRecipe, deleteRecipe } from '../api/recipe';
import { imageUrlFromObject } from './utils/image-url';

const slice = createSlice({
  name: 'recipe',
  initialState: {
    recipe: {},
    loading: false,
    saving: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // @ts-ignore
    builder.addCase(getRecipe.fulfilled, (state, action) => {
      const { recipe } = action.payload;
      if (!recipe.imageUrl) {
        recipe.imageUrl = imageUrlFromObject(recipe.image);
      }
      return {
        recipe,
        loading: false,
        error: null,
      };
    });
    // @ts-ignore
    builder.addCase(getRecipe.rejected, (state, action) => {
      return { ...state, error: action.error.message, loading: false };
    });
    // @ts-ignore
    builder.addCase(getRecipe.pending, (state, action) => {
      return { ...state, loading: true };
    });

    // @ts-ignore
    builder.addCase(putRecipe.fulfilled, (state, action) => {
      return {
        recipe: action.payload.recipe,
        saving: false,
        error: null,
      };
    });
    // @ts-ignore
    builder.addCase(putRecipe.rejected, (state, action) => {
      return { ...state, error: action.error.message, saving: false };
    });
    // @ts-ignore
    builder.addCase(putRecipe.pending, (state, action) => {
      return { ...state, saving: true };
    });

    // @ts-ignore
    builder.addCase(postRecipe.fulfilled, (state, action) => {
      return {
        recipe: action.payload.recipe,
        saving: false,
        error: null,
      };
    });
    // @ts-ignore
    builder.addCase(postRecipe.rejected, (state, action) => {
      return { ...state, error: action.error.message, saving: false };
    });
    // @ts-ignore
    builder.addCase(postRecipe.pending, (state, action) => {
      return { ...state, saving: true };
    });

    // @ts-ignore
    builder.addCase(deleteRecipe.fulfilled, (state, action) => {
      return {
        recipe: {},
        saving: false,
        error: null,
      };
    });
    // @ts-ignore
    builder.addCase(deleteRecipe.rejected, (state, action) => {
      return { ...state, error: action.error.message, saving: false };
    });
    // @ts-ignore
    builder.addCase(deleteRecipe.pending, (state, action) => {
      return { ...state, saving: true };
    });
  },
});
export default slice.reducer;
