import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ICredentials } from './types/authentication';
import axios from '../api/axios';

const STORAGE_KEY = 'authentication';

const getLocalStorageAccessToken = () => {
  const data = localStorage.getItem(STORAGE_KEY);
  if (data === null) {
    return data;
  }
  const authentication = JSON.parse(data);
  return authentication.accessToken;
};

const sliceName = 'authentication';

export const setAuth = createAsyncThunk(
  'setAuth',
  async (credentials: ICredentials, { getState }: { getState: Function }) => {
    try {
      const { data } = await axios.post(`/${sliceName}`, {
        strategy: 'local',
        ...credentials,
      });
      const { accessToken } = data;
      return { accessToken };
    } catch (error) {
      throw error;
    }
  },
);

const slice = createSlice({
  name: sliceName,
  initialState: {
    accessToken: getLocalStorageAccessToken(),
    activity: false,
    error: null,
  },
  reducers: {
    clearAuth: (state) => {
      state.accessToken = null;
      localStorage.removeItem(STORAGE_KEY);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setAuth.fulfilled, (state, action) => {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(action.payload));
      return {
        accessToken: action.payload.accessToken,
        activity: false,
        error: null,
      };
    });
    /* @todo
      Argument of type 'AsyncThunkRejectedActionCreator<ICredentials, {}>' is not assignable to parameter of type 'string'.
    */
    //@ts-ignore
    builder.addCase(setAuth.rejected, (state, action) => {
      return { ...state, error: action.error.message, activity: false };
    });
    builder.addCase(setAuth.pending, (state, action) => {
      return { ...state, activity: true };
    });
  },
});
export default slice.reducer;

const { clearAuth } = slice.actions;

export const logout = () => (dispatch: Function) => {
  return dispatch(clearAuth());
};
