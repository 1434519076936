import { createSlice } from '@reduxjs/toolkit';
import { imageUrlFromObject } from './utils/image-url';
import { IRecipe } from './types/recipe';

const sliceName = 'search';

interface IRecipesSlice {
  recipes: null | [];
  activity: boolean;
  error: null | string;
}

const slice = createSlice({
  name: sliceName,
  initialState: {
    recipes: null,
    activity: false,
    error: null,
  } as IRecipesSlice,
  reducers: {
    start: (state) => {
      state.activity = true;
      state.error = null;
    },
    setRecipes: (state, action) => {
      const recipes = action.payload.data.map((recipe: IRecipe) => {
        recipe.imageUrl = imageUrlFromObject(recipe.image);
        return recipe;
      });
      state.recipes = recipes;
      state.activity = false;
    },
    error: (state, action) => {
      state.error = action.payload.message;
      state.activity = false;
    },
    resetSearch: (state) => {
      state.recipes = null;
    },
  },
});

export const { start, setRecipes, error, resetSearch } = slice.actions;

export default slice.reducer;

export const clearSearch = () => async (dispatch: any) =>
  dispatch(resetSearch());
