import { createSlice } from '@reduxjs/toolkit';
import { getImage, postImage } from '../api/image';

const slice = createSlice({
  name: 'image',
  initialState: {
    image: {},
    loading: false,
    savingImage: false,
    errorImage: null,
  },
  reducers: {
    resetImage: (state) => {
      state.image = {};
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore
    builder.addCase(getImage.fulfilled, (state, action) => {
      return {
        image: action.payload.image,
        loading: false,
        errorImage: null,
      };
    });
    // @ts-ignore
    builder.addCase(getImage.rejected, (state, action) => {
      return { ...state, errorImage: action.error.message, loading: false };
    });
    // @ts-ignore
    builder.addCase(getImage.pending, (state, action) => {
      return { ...state, loading: true };
    });

    // @ts-ignore
    builder.addCase(postImage.fulfilled, (state, action) => {
      return {
        image: action.payload.image,
        savingImage: false,
        errorImage: null,
      };
    });
    // @ts-ignore
    builder.addCase(postImage.rejected, (state, action) => {
      return { ...state, errorImage: action.error.message, savingImage: false };
    });
    // @ts-ignore
    builder.addCase(postImage.pending, (state, action) => {
      return { ...state, savingImage: true };
    });
  },
});
export default slice.reducer;

const { resetImage } = slice.actions;

export const clearImage = () => async (dispatch: any) => dispatch(resetImage());
