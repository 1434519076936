import React from 'react';
import { Route, Redirect } from 'react-router-dom';

interface IPrivateRoute {
  component: React.FC;
  path: string;
  isAuthenticated: boolean;
  exact: boolean;
}

const PrivateRoute = ({
  component,
  exact,
  path,
  isAuthenticated,
}: IPrivateRoute) => {
  return isAuthenticated ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to="/login" />
  );
};

export default PrivateRoute;
