import { createSlice } from '@reduxjs/toolkit';
import { getCategories } from '../api/categories';

const slice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
    shouldRefresh: false,
    activity: false,
    error: null,
  },
  reducers: {
    shouldRefresh: (state) => {
      state.shouldRefresh = true;
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore
    builder.addCase(getCategories.fulfilled, (state, action) => {
      return {
        categories: [...action.payload.categories],
        activity: false,
        error: null,
      };
    });
    // @ts-ignore
    builder.addCase(getCategories.rejected, (state, action) => {
      return { ...state, error: action.error.message, activity: false };
    });
    // @ts-ignore
    builder.addCase(getCategories.pending, (state, action) => {
      return { ...state, activity: true };
    });
  },
});
export default slice.reducer;

const { shouldRefresh } = slice.actions;

export const setShouldRefresh = () => async (dispatch: any) =>
  dispatch(shouldRefresh());
