import { createSlice } from '@reduxjs/toolkit';
import { getCuisines } from '../api/cuisines';

const slice = createSlice({
  name: 'cuisines',
  initialState: {
    cuisines: [],
    shouldRefresh: false,
    activity: false,
    error: null,
  },
  reducers: {
    shouldRefresh: (state) => {
      state.shouldRefresh = true;
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore
    builder.addCase(getCuisines.fulfilled, (state, action) => {
      return {
        cuisines: [...action.payload.cuisines],
        activity: false,
        error: null,
      };
    });
    // @ts-ignore
    builder.addCase(getCuisines.rejected, (state, action) => {
      return { ...state, error: action.error.message, activity: false };
    });
    // @ts-ignore
    builder.addCase(getCuisines.pending, (state, action) => {
      return { ...state, activity: true };
    });
  },
});
export default slice.reducer;

const { shouldRefresh } = slice.actions;

export const setShouldRefresh = () => async (dispatch: any) =>
  dispatch(shouldRefresh());
