import { createAsyncThunk } from '@reduxjs/toolkit';
import { History } from 'history';
import { IRecipe } from '../store/types/recipe';
import axios from './axios';

// const timeout = (ms: number) =>
//   new Promise((resolve) => setTimeout(resolve, ms));

export const getRecipe = createAsyncThunk(
  'getRecipe',
  async ({ id }: { id: string }, { getState }: { getState: Function }) => {
    let recipe;
    const { recipes } = getState().search;
    /*
      @todo, push all results to a cache
      or look for caching lib
     */
    if (Array.isArray(recipes)) {
      recipe = recipes.find((recipe: IRecipe) => recipe._id === id);
    }
    if (!recipe) {
      const { data } = await axios.get(`/recipes/${id}`, {});
      recipe = data;
    }

    return { recipe };
  },
);

export const putRecipe = createAsyncThunk(
  'putRecipe',
  async (
    { recipe }: { recipe: IRecipe },
    { getState }: { getState: Function },
  ) => {
    const { data } = await axios.put(`/recipes/${recipe._id}`, recipe);
    recipe = data;
    // await timeout(1000);
    return { recipe };
  },
);

export const postRecipe = createAsyncThunk(
  'postRecipe',
  async (
    { recipe, history }: { recipe: IRecipe; history: History<unknown> },
    { getState }: { getState: Function },
  ) => {
    const { data } = await axios.post(`/recipes/`, recipe);
    recipe = data;
    // await timeout(1000);
    history.push(`/home/recipes/${recipe._id}`);
    return { recipe };
  },
);

export const deleteRecipe = createAsyncThunk(
  'deleteRecipe',
  async (
    { recipe }: { recipe: IRecipe },
    { getState }: { getState: Function },
  ) => {
    return await axios.delete(`/recipes/${recipe._id}`);
  },
);
