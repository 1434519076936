import { call, takeLatest, put, delay } from 'redux-saga/effects';
import axios from './axios';
import { start, setRecipes, error } from '../store/search';
import { sagaActions } from '../constants/saga-actions';

export interface ISearch {
  favorite?: boolean | undefined;
  $search?: string | undefined;
  $limit: number;
  _deleted: boolean;
  '$sort[favorite]': number;
  '$sort[name]': number;
}

const findRecipes = async ({ params }: { params: any }) => {
  return await axios.get(`/recipes`, {
    params,
  });
};

export function* doSearch(action: any) {
  yield put(start());
  yield delay(300);
  try {
    //@ts-ignore
    let result = yield call(findRecipes, action.payload);
    yield put(setRecipes(result.data));
  } catch (e) {
    yield put(error(e));
  }
}

export default function* onInput() {
  yield takeLatest(sagaActions.SEARCH, doSearch);
}
