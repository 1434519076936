import axios from 'axios';
import { API } from './service-paths';
import { getStore } from '../store';

const paramsSerializer = (params: any) =>
  new URLSearchParams(params).toString();

const getAuthorization = () => {
  const store = getStore();
  const { authentication } = store.getState();
  const Authorization = authentication.accessToken;
  if (!Authorization) {
    return {};
  }
  return { Authorization };
};

axios.defaults.baseURL = API;

axios.interceptors.request.use(
  (config) => {
    config.headers = {
      ...getAuthorization(),
      ...config.headers,
    };
    config.paramsSerializer = paramsSerializer;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axios;
