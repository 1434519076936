import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authentication from './authentication';
import recipe from './recipe';
import search from './search';
import categories from './categories';
import cuisines from './cuisines';
import image from './image';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import searchSaga from '../api/search-saga';

const reducer = combineReducers({
  authentication,
  recipe,
  search,
  categories,
  cuisines,
  image,
});

let sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({ thunk: true }),
  thunk,
  sagaMiddleware,
];

export const store = configureStore({
  reducer,
  middleware,
});

sagaMiddleware.run(searchSaga);

// export method to get store so http can get accessToken
export function getStore() {
  return store;
}
